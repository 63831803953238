
export default {
  gender: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ],
  birthDate: [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ],
  state: [
    { value: "alabama", label: "Alabama" },
    { value: "alaska", label: "Alaska" },
    { value: "arizona", label: "Arizona" },
    { value: "arkansas", label: "Arkansas" },
    { value: "california", label: "California" },
    { value: "colorado", label: "Colorado" },
    { value: "connecticut", label: "Connecticut" },
    { value: "delaware", label: "Delaware" },
    { value: "florida", label: "Florida" },
    { value: "georgia", label: "Georgia" },
    { value: "hawaii", label: "Hawaii" },
    { value: "idaho", label: "Idaho" },
    { value: "illinois", label: "Illinois" },
    { value: "indiana", label: "Indiana" },
    { value: "iowa", label: "Iowa" },
    { value: "kansas", label: "Kansas" },
    { value: "kentucky", label: "Kentucky" },
    { value: "louisiana", label: "Louisiana" },
    { value: "maine", label: "Maine" },
    { value: "maryland", label: "Maryland" },
    { value: "massachusetts", label: "Massachusetts" },
    { value: "michigan", label: "Michigan" },
    { value: "minnesota", label: "Minnesota" },
    { value: "mississippi", label: "Mississippi" },
    { value: "missouri", label: "Missouri" },
    { value: "montana", label: "Montana" },
    { value:  "nebraska", label: "Nebraska" },
    { value: "nevada", label: "Nevada" },
    { value: "new hampshire", label: "New Hampshire" },
    { value: "new jersey", label: "New Jersey" },
    { value: "new mexico", label: "New Mexico" },
    { value: "new york", label: "New York" },
    { value: "north carolina", label: "North Carolina" },
    { value: "north dakota", label: "North Dakota" },
    { value: "ohio", label: "Ohio" },
    { value: "oklahoma", label: "Oklahoma" },
    { value: "oregon", label: "Oregon" },
    { value: "pennsylvania", label: "Pennsylvania" },
    { value: "rhode island", label: "Rhode Island" },
    { value: "south carolina", label: "South Carolina" },
    { value: "south dakota", label: "South Dakota" },
    { value: "tennessee", label: "Tennessee" },
    { value: "texas", label: "Texas" },
    { value: "utah", label: "Utah" },
    { value: "vermont", label: "Vermont" },
    { value: "virginia", label: "Virginia" },
    { value: "washington", label: "Washington" },
    { value: "west virginia", label: "West Virginia" },
    { value: "wisconsin", label: "Wisconsin" },
    { value: "wyoming", label: "Wyoming" },
  ],
  
  days: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ],
  years: [
    { value: 1950, label: 1950 },
    { value: 1951, label: 1951 },
    { value: 1952, label: 1952 },
    { value: 1953, label: 1953 },
    { value: 1954, label: 1954 },
    { value: 1955, label: 1955 },
    { value: 1956, label: 1956 },
    { value: 1957, label: 1957 },
    { value: 1958, label: 1958 },
    { value: 1959, label: 1959 },
    { value: 1960, label: 1960 },
    { value: 1961, label: 1961 },
    { value: 1962, label: 1962 },
    { value: 1963, label: 1963 },
    { value: 1964, label: 1964 },
    { value: 1965, label: 1965 },
    { value: 1966, label: 1966 },
    { value: 1967, label: 1967 },
    { value: 1968, label: 1968 },
    { value: 1969, label: 1969 },
    { value: 1970, label: 1970 },
    { value: 1971, label: 1971 },
    { value: 1972, label: 1972 },
    { value: 1973, label: 1973 },
    { value: 1974, label: 1974 },
    { value: 1975, label: 1975 },
    { value: 1976, label: 1976 },
    { value: 1977, label: 1977 },
    { value: 1978, label: 1978 },
    { value: 1979, label: 1979 },
    { value: 1980, label: 1980 },
    { value: 1981, label: 1981 },
    { value: 1982, label: 1982 },
    { value: 1983, label: 1983 },
    { value: 1984, label: 1984 },
    { value: 1985, label: 1985 },
    { value: 1986, label: 1986 },
    { value: 1987, label: 1987 },
    { value: 1988, label: 1988 },
    { value: 1989, label: 1989 },
    { value: 1990, label: 1990 },
    { value: 1991, label: 1991 },
    { value: 1992, label: 1992 },
    { value: 1993, label: 1993 },
    { value: 1994, label: 1994 },
    { value: 1995, label: 1995 },
    { value: 1996, label: 1996 },
    { value: 1997, label: 1997 },
    { value: 1998, label: 1998 },
    { value: 1999, label: 1999 },
    { value: 2000, label: 2000 },
    { value: 2001, label: 2001 },
    { value: 2002, label: 2002 },
    { value: 2003, label: 2003 },
    { value: 2004, label: 2004 },
    { value: 2005, label: 2005 },
    { value: 2006, label: 2006 },
    { value: 2007, label: 2007 },
    { value: 2008, label: 2008 },
    { value: 2009, label: 2009 },
    { value: 2010, label: 2010 },

  ],
};
